import * as actionTypes from "../actionTypes";
import Axios from "../../../services/NetworkService";
// EDIT_CUSTOM_SUBSCRIBER
export const editCustomSubscriberPending = () => {
  return {
    type: actionTypes.EDIT_CUSTOM_SUBSCRIBER_PENDING,
  };
};

export const editCustomSubscriberSuccess = (data) => {
  return {
    type: actionTypes.EDIT_CUSTOM_SUBSCRIBER_SUCCESS,
    data: data,
  };
};

export const editCustomSubscriberFail = (error) => {
  return {
    type: actionTypes.EDIT_CUSTOM_SUBSCRIBER_FAIL,
    error: error,
  };
};

export const editCustomSubscriberAction = (ags, id, customSubscriberInfo) => {
  let urlEndPoint = `subscriber/${id}?ags=${ags}`;
  return async (dispatch) => {
    try {
      dispatch(editCustomSubscriberPending);

      const response = await Axios.patch(urlEndPoint, customSubscriberInfo);
      return dispatch(editCustomSubscriberSuccess(response.data));
    } catch (error) {
      return dispatch(editCustomSubscriberFail(error));
    }
  };
};
