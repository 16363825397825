import * as actionTypes from "../actionTypes";
import Axios from "../../../services/NetworkService";

export const deleteUserPending = () => {
  return {
    type: actionTypes.DELETE_USER_PENDING,
  };
};

export const deleteUserSuccess = (data) => {
  return {
    type: actionTypes.DELETE_USER_SUCCESS,
    data: data,
  };
};

export const deleteUserFail = (error) => {
  return {
    type: actionTypes.DELETE_USER_FAIL,
    error: error,
  };
};

export const deleteUserAction = (userId) => {
  let urlEndPoint = `/users/${userId}`;

  return async (dispatch) => {
    try {
      dispatch(deleteUserPending());

      const response = await Axios.delete(urlEndPoint);
      return dispatch(deleteUserSuccess(response.data));
    } catch (error) {
      return dispatch(deleteUserFail(error));
    }
  };
};
