import * as actionTypes from "../actionTypes";
import Axios from "../../../services/NetworkService";

export const allCommunitiesPending = () => {
  return {
    type: actionTypes.ALL_COMMUNITIES_PENDING,
  };
};

export const allCommunitiesSuccess = (data) => {
  return {
    type: actionTypes.ALL_COMMUNITIES_SUCCESS,
    data: data,
  };
};

export const allCommunitiesFail = (error) => {
  return {
    type: actionTypes.ALL_COMMUNITIES_FAIL,
    error: error,
  };
};

export const allCommunitiesAction = (searchKeyword) => {
  return async (dispatch) => {
    try {
      dispatch(allCommunitiesPending());
      const response = await Axios.get(
        "/ags/findWithRegistrableCustomers?searchTerm=" + searchKeyword
      );
      return dispatch(allCommunitiesSuccess(response.data));
    } catch (error) {
      return dispatch(allCommunitiesFail(error));
    }
  };
};
