import * as actionTypes from "../actionTypes";
import Axios from "../../../services/NetworkService";

export const gflInit = () => {
  return {
    type: actionTypes.GFL_INIT,
  };
};

export const gflSuccess = (data) => {
  return {
    type: actionTypes.GFL_SUCCESS,
    data: data,
  };
};

export const gflFail = (error) => {
  return {
    type: actionTypes.GFL_FAIL,
    error: error,
  };
};

export const gfl = (query) => {
  let urlEndPoint = "/gfl";
  const queries = [];

  if (typeof query === "object") {
    Object.keys(query).forEach((k) => {
      queries.push(`${k}=${query[k]}`);
    });
  }

  if (queries.length > 0) {
    urlEndPoint += `?${queries.join("&")}`;
  }

  return (dispatch) => {
    dispatch(gflInit());
    Axios.get(urlEndPoint, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        // Authorization: token,
      },
    })
      .then((response) => {
        dispatch(gflSuccess(response.data.data));
      })
      .catch((error) => {
        dispatch(gflFail(error));
      });
  };
};
