/*
 * Title: hdc reducer
 * Description: hdc sensor reducer
*/

import { updateObject } from '../../../shared/Utility';
import * as actionTypes from '../../actions/actionTypes';

const initialState = {
    isLoading: false,
    data: null,
    error: null,
}

const hdcInit = (state) => {
    return updateObject(state, {
        isLoading: true,
        data: null,
        error: null,
    });
}

const hdcSuccess = (state, data) => {
    return updateObject(state, {
        isLoading: false,
        data: data,
        error: null
    });
}

const hdcFail = (state, error) => {
    return updateObject(state, {
        isLoading: false,
        data: null,
        error: error
    });
}

const reducer = (state = initialState, action) => {
    const { type, data, error } = action;
    switch (type) {
        case actionTypes.HDC_INIT: return hdcInit(state);
        case actionTypes.HDC_SUCCESS: return hdcSuccess(state, data);
        case actionTypes.HDC_FAIL: return hdcFail(state, error);
        default: return state;
    }

};

export default reducer;