import { updateObject } from '../../../shared/Utility';
import * as actionTypes from '../../actions/actionTypes';

const initialState = {
  isLoading: false,
  data: null,
  error: null,
}

const usersPending = (state) => {
  return updateObject(state, {
    isLoading: true,
    data: null,
    error: null,
  });
}

const usersSuccess = (state, data) => {
  return updateObject(state, {
    isLoading: false,
    data: data,
    error: null
  });
}

const usersFail = (state, error) => {
  return updateObject(state, {
    isLoading: false,
    data: null,
    error: error
  });
}

const usersReset = (state) => {
  return updateObject(state, {
    isLoading: false,
    data: null,
    error: null,
  });
}

const reducer = (state = initialState, action) => {
  const { type, data, error } = action;
  switch (type) {
    case actionTypes.USERS_PENDING: return usersPending(state);
    case actionTypes.USERS_SUCCESS: return usersSuccess(state, data);
    case actionTypes.USERS_FAIL: return usersFail(state, error);
    case actionTypes.USERS_RESET: return usersReset(state);
    default: return state;
  }
};

export default reducer;