import * as actionTypes from "../actionTypes";
import Axios from "../../../services/NetworkService";

export const smartriverInit = () => {
  return {
    type: actionTypes.SMARTRIVER_INIT,
  };
};

export const smartriverSuccess = (data) => {
  return {
    type: actionTypes.SMARTRIVER_SUCCESS,
    data: data,
  };
};

export const smartriverFail = (error) => {
  return {
    type: actionTypes.SMARTRIVER_FAIL,
    error: error,
  };
};

export const smartriver = (query) => {
  let urlEndPoint = "/smartriver";
  const queries = [];

  if (typeof query === "object") {
    Object.keys(query).forEach((k) => {
      queries.push(`${k}=${query[k]}`);
    });
  }

  if (queries.length > 0) {
    urlEndPoint += `?${queries.join("&")}`;
  }

  return (dispatch) => {
    dispatch(smartriverInit());
    Axios.get(urlEndPoint, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => {
        dispatch(smartriverSuccess(response.data.data));
      })
      .catch((error) => {
        dispatch(smartriverFail(error));
      });
  };
};
