import * as actionTypes from "../actionTypes";
import Axios from "../../../services/NetworkService";

export const municipalityPending = () => {
  return {
    type: actionTypes.MUNICIPALITY_PENDING,
  };
};

export const municipalitySuccess = (data) => {
  return {
    type: actionTypes.MUNICIPALITY_SUCCESS,
    data: data,
  };
};

export const municipalityFail = (error) => {
  return {
    type: actionTypes.MUNICIPALITY_FAIL,
    error: error,
  };
};

export const municipality = () => {
  return async (dispatch) => {
    try {
      dispatch(municipalityPending());
      const response = await Axios.get("/customers/getAllAgs");
      return dispatch(municipalitySuccess(response.data));
    } catch (error) {
      return dispatch(municipalityFail(error));
    }
  };
};
