import * as actionTypes from "../actionTypes";
import Axios from "../../../services/NetworkService";
import store from "../../store/store";

export const gflSensorPending = () => {
  return {
    type: actionTypes.GFL_SENSOR_PENDING
  };
};

export const gflSensorComplete = () => {
  return {
    type: actionTypes.GFL_SENSOR_COMPLETE,
  };
};

export const gflSensorSuccess = (data) => {
  return {
    type: actionTypes.GFL_SENSOR_SUCCESS,
    payload: data,
  };
};

export const gflSensorFail = (error) => {
  return {
    type: actionTypes.GFL_SENSOR_FAIL,
    error: error,
  };
};

export const gflSensorReset = () => {
  return {
    type: actionTypes.GFL_SENSOR_RESET,
  };
};

const fetchGflSensorsData = async (timeFrame, sensors, dispatch) => {
  const gflData = [];
  const gflIds = [];

  for (let i = 0; i < sensors.length; i++) {
    if (store.getState().gflSensor.isTerminated) {
      dispatch(gflSensorReset());
      break;
    }

    const sensorName = sensors[i].label;
    const sensorId = sensors[i].value;

    gflData.push({
      sensorName,
      sensorId,
      values: [],
    });
    gflIds.push(sensorId);
  }

  if (gflIds.length > 0) {
    const result = await Axios.post("/gfldata", {
      timeFrame,
      deviceIds: gflIds,
    });
    gflData.forEach((d) => (d.values = result.data.data[d.sensorId] || []));
    await dispatch(gflSensorSuccess({ gflData }));
  }

  dispatch(gflSensorComplete());
}

export const gflSensorAction = (timeframe, sensors) => {
  return async function (dispatch) {
    try {
      dispatch(gflSensorPending());
      fetchGflSensorsData(timeframe, sensors, dispatch);
    } catch (error) {
      return dispatch(gflSensorFail(error));
    }
  };
};