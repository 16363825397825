import * as actionTypes from "../actionTypes";
import Axios from "../../../services/NetworkService";

const deleteCustomSubscribersPending = () => {
  return {
    type: actionTypes.DELETE_CUSTOM_SUBSCRIBER_PENDING,
  };
};
const deleteCustomSubscribersSuccess = (data) => {
  return {
    type: actionTypes.DELETE_CUSTOM_SUBSCRIBER_SUCCESS,
    data: data,
  };
};

const deleteCustomSubscribersFail = (error) => {
  return {
    type: actionTypes.DELETE_CUSTOM_SUBSCRIBER_FAIL,
    error: error,
  };
};

export const deleteCustomSubscribersAction = (ags, id) => {
  let urlEndPoint = `subscriber/customSub/${id}?ags=${ags}`;

  return async (dispatch) => {
    try {
      dispatch(deleteCustomSubscribersPending());
      const response = await Axios.delete(urlEndPoint);
      return dispatch(deleteCustomSubscribersSuccess(response.data));
    } catch (error) {
      return dispatch(deleteCustomSubscribersFail(error));
    }
  };
};
