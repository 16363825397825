import * as actionTypes from "../../actions/actionTypes.js";
import { updateObject } from "../../../shared/Utility";

const initialState = {
  loading: false,
  error: null,
  data: null,
};

const municipalityPending = (state) => {
  return updateObject(state, {
    loading: true,
    error: null,
    data: null,
  });
};

const municipalitySuccess = (state, data) => {
  return updateObject(state, {
    loading: false,
    error: null,
    data: data,
  });
};

const municipalityFail = (state, error) => {
  return updateObject(state, {
    loading: false,
    data: null,
    error: error,
  });
};

const reducer = (state = initialState, action) => {
  const { type, data, error } = action;
  switch (type) {
    case actionTypes.MUNICIPALITY_PENDING:
      return municipalityPending(state);
    case actionTypes.MUNICIPALITY_SUCCESS:
      return municipalitySuccess(state, data);
    case actionTypes.MUNICIPALITY_FAIL:
      return municipalityFail(state, error);
    default:
      return state;
  }
};

export default reducer;
