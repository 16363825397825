import * as actionTypes from "../actionTypes";
import Axios from "../../../services/NetworkService";

const harmReportsPending = () => {
  return {
    type: actionTypes.HARM_REPORTS_PENDING,
  };
};

const harmReportsSuccess = (data) => {
  return {
    type: actionTypes.HARM_REPORTS_SUCCESS,
    data: data,
  };
};

const harmReportsFail = (error) => {
  return {
    type: actionTypes.HARM_REPORTS_FAIL,
    error: error,
  };
};

export const turnOffHarmReports = () => {
  return {
    type: actionTypes.HARM_REPORTS_OFF,
  };
};

export const harmReports = (ags) => {
  const urlEndpoint = `${process.env.REACT_APP_BASE_URL}/harmreport?ags=${ags}`;

  return (dispatch) => {
    dispatch(harmReportsPending());
    Axios.get(urlEndpoint, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => {
        dispatch(harmReportsSuccess(response.data.result));
      })
      .catch((error) => {
        dispatch(harmReportsFail(error));
      });
  };
};
