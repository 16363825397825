import * as actionTypes from "../actionTypes";
import Axios from "../../../services/NetworkService";
import store from "../../store/store";

// const testToken =
// "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2MWE4Njc0ZmIwOWIyZDZiNmQwMTcyNTUiLCJpYXQiOjE2NDU2MTMxNzcsImV4cCI6MTY0NjIxNzk3N30.ubCYPLhINnZPAdYXkFb6gRoKH5ZYBvFdTpz0wW8McU8";
const webviewCommentsPending = () => {
  return {
    type: actionTypes.WEBVIEW_COMMENTS_PENDING,
  };
};

const webviewCommentsSuccess = (data) => {
  return {
    type: actionTypes.WEBVIEW_COMMENTS_SUCCESS,
    data: data,
  };
};
const webviewCommentsRemoveSuccess = (data, message) => {
  return {
    type: actionTypes.WEBVIEW_COMMENTS_REMOVE_SUCCESS,
    data: data,
    message: message,
  };
};

const webviewCommentsFail = (error) => {
  return {
    type: actionTypes.WEBVIEW_COMMENTS_FAIL,
    error: error,
  };
};

export const toggleComments = () => {
  return {
    type: actionTypes.WEBVIEW_COMMENTS_STATUS,
  };
};

export const updateNewCmntStatus = (status) => {
  return {
    type: actionTypes.WEBVIEW_ADD_NEW_COMMENTS_STATUS,
    data: status,
  };
};

const updateWebviewCommentsSuccess = (data) => {
  return {
    type: actionTypes.UPDATE_WEBVIEW_COMMENTS_SUCCESS,
    data: data,
  };
};

const updateWebviewCommentsFail = (error) => {
  return {
    type: actionTypes.UPDATE_WEBVIEW_COMMENTS_FAIL,
    error: error,
  };
};

//------------
//This funciton now running on test environments
export const addWebViewComment = (name, description, geoJson, attachments) => {
  const urlEndPoint = `${process.env.REACT_APP_BASE_URL}/webviewercomments`;
  const data = {
    name: name,
    description: description,
    markerCoordinates: geoJson,
    markerType: "Polygon",
    files: attachments,
  };

  const prevComments = store.getState().webviewComments.data;

  return async (dispatch) => {
    try {
      dispatch(webviewCommentsPending());

      const response = await Axios.post(urlEndPoint, data, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          // Authorization: testToken,
        },
      });
      const comments = [...prevComments, response.data];
      return dispatch(webviewCommentsSuccess(comments));
    } catch (error) {
      return dispatch(webviewCommentsFail(error));
    }
  };
};

export const removeWebViewComment = (markerId) => {
  const urlEndPoint = `${process.env.REACT_APP_BASE_URL}/webviewercomments/remove/${markerId}`;
  const prevComments = store.getState().webviewComments.data;

  return (dispatch) => {
    dispatch(webviewCommentsPending());
    Axios.delete(urlEndPoint, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        // Authorization: testToken,
      },
    })
      .then((response) => {
        const updateComments = prevComments.filter(
          (comment) => comment._id !== markerId
        );
        const deleteMessage = response.data;
        dispatch(webviewCommentsRemoveSuccess(updateComments, deleteMessage));
      })
      .catch((error) => {
        dispatch(webviewCommentsFail(error));
      });
  };
};

export const fetchWebViewComments = () => {
  const urlEndpoint = `${process.env.REACT_APP_BASE_URL}/webviewercomments`;

  return (dispatch) => {
    dispatch(webviewCommentsPending());
    Axios.get(urlEndpoint, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        // Authorization: testToken,
      },
    })
      .then((response) => {
        dispatch(webviewCommentsSuccess(response.data));
      })
      .catch((error) => {
        dispatch(webviewCommentsFail(error));
      });
  };
};

export const updateWebviewComments = (data, id) => {
  const urlEndpoint = `${process.env.REACT_APP_BASE_URL}/webviewercomments/${id}`;

  return async (dispatch) => {
    try {
      dispatch(webviewCommentsPending());

      const response = await Axios.patch(urlEndpoint, data, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          // Authorization: testToken,
        },
      });
      return dispatch(updateWebviewCommentsSuccess(response.data));
    } catch (error) {
      return dispatch(updateWebviewCommentsFail(error));
    }
  };
};
