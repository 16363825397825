import * as actionTypes from "../actionTypes";
import Axios from "../../../services/NetworkService";

export const startRegisterPending = () => {
  return {
    type: actionTypes.START_REGISTER_PENDING,
  };
};

export const startRegisterSuccess = (data) => {
  return {
    type: actionTypes.START_REGISTER_SUCCESS,
    data: data,
  };
};

export const startRegisterFail = (error) => {
  return {
    type: actionTypes.START_REGISTER_FAIL,
    error: error,
  };
};

export const startRegisterAction = (ags, newUserData) => {
  let urlEndPoint = `/users/startRegistrationProcess/?ags=${ags}`;

  return async (dispatch) => {
    try {
      dispatch(startRegisterPending());

      const response = await Axios.post(urlEndPoint, newUserData);
      return dispatch(startRegisterSuccess(response.data));
    } catch (error) {
      return dispatch(startRegisterFail(error));
    }
  };
};
