import { updateObject } from "../../../shared/Utility";
import * as actionTypes from "../../actions/actionTypes";

const initialState = {
  // store collapsed notifications id
  collapsedItem: [],
};

const addId = (state, id) => {
  const data = [...state.collapsedItem];
  data.push(id);
  return updateObject(state, {
    collapsedItem: data,
  });
};

const reducer = (state = initialState, action) => {
  const { type, id } = action;
  switch (type) {
    case actionTypes.ADD_COLLAPSED_NOTIFICATION:
      return addId(state, id);
    default:
      return state;
  }
};

export default reducer;
