import * as actionTypes from "../actionTypes";
import Axios from "../../../services/NetworkService";

export const customerActionPending = () => {
  return {
    type: actionTypes.CUSTOMER_PENDING,
  };
};

export const customerActionSuccess = (data) => {
  return {
    type: actionTypes.CUSTOMER_SUCCESS,
    data: data,
  };
};

export const customerActionFail = (error) => {
  return {
    type: actionTypes.CUSTOMER_FAIL,
    error: error,
  };
};

export const customerAction = (ags = "") => {
  let urlEndPoint = "/customers";
  if (ags) {
    urlEndPoint = `/customers?ags=${ags}`;
  }
  return async (dispatch) => {
    dispatch(customerActionPending());
    Axios.get(urlEndPoint, {
      headers: {
        // Authorization: token,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        dispatch(customerActionSuccess(response.data));
      })
      .catch((error) => {
        dispatch(customerActionFail(error));
      });
  };
};
