import { updateObject } from '../../../shared/Utility';
import * as actionTypes from '../../actions/actionTypes';

const initialState = {
  isLoading: false,
  data: null,
  error: null,
}

const deleteAccountPending = (state) => {
  return updateObject(state, {
    isLoading: true,
    data: null,
    error: null,
  });
}

const deleteAccountSuccess = (state, data) => {
  return updateObject(state, {
    isLoading: false,
    data: data,
    error: null
  });
}

const deleteAccountFail = (state, error) => {
  return updateObject(state, {
    isLoading: false,
    data: null,
    error: error
  });
}

const reducer = (state = initialState, action) => {
  const { type, data, error } = action;
  switch (type) {
    case actionTypes.DELETE_ACCOUNT_PENDING: return deleteAccountPending(state);
    case actionTypes.DELETE_ACCOUNT_SUCCESS: return deleteAccountSuccess(state, data);
    case actionTypes.DELETE_ACCOUNT_FAIL: return deleteAccountFail(state, error);
    default: return state;
  }

};

export default reducer;