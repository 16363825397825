import * as actionTypes from "../actionTypes";
import Axios from "../../../services/NetworkService";
import store from "../../store/store";

export const smartRiverSensorPending = () => {
  return {
    type: actionTypes.SMART_RIVER_PENDING,
  };
};

export const smartRiverSensorComplete = () => {
  return {
    type: actionTypes.SMART_RIVER_COMPLETE,
  };
};

export const smartRiverSensorSuccess = (data) => {
  return {
    type: actionTypes.SMART_RIVER_SUCCESS,
    payload: data,
  };
};

export const smartRiverSensorFail = (error) => {
  return {
    type: actionTypes.SMART_RIVER_FAIL,
    error: error,
  };
};

export const smartRiverSensorReset = () => {
  return {
    type: actionTypes.SMART_RIVER_RESET,
  };
};

const fetchSmartRiverSensorsData = async (timeFrame, sensors, dispatch) => {
  const smartRiverData = [];
  const smartRiverIds = [];

  for (let i = 0; i < sensors.length; i++) {
    if (store.getState().smartRiverSensor.isTerminated) {
      dispatch(smartRiverSensorReset());
      break;
    }

    const sensorName = sensors[i].label;
    const sensorId = sensors[i].value;
    const soleCorrectionValue =
      sensors[i].soleCorrectionValue ?? sensors[i].soleCorrectionValue;

    smartRiverData.push({
      soleCorrectionValue,
      sensorName,
      sensorId,
      values: [],
    });
    smartRiverIds.push(sensorId);
  }

  if (smartRiverIds.length > 0) {
    const result = await Axios.post("/smartriverdata", {
      timeFrame,
      deviceIds: smartRiverIds,
      filter: true,
    });
    smartRiverData.forEach(
      (d) => (d.values = result.data.data[d.sensorId] || [])
    );
    await dispatch(smartRiverSensorSuccess({ smartRiverData }));
  }

  dispatch(smartRiverSensorComplete());
};

export const smartRiverSensorAction = (timeframe, sensors) => {
  return async function (dispatch) {
    try {
      dispatch(smartRiverSensorPending());
      fetchSmartRiverSensorsData(timeframe, sensors, dispatch);
    } catch (error) {
      return dispatch(smartRiverSensorFail(error));
    }
  };
};
