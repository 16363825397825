import React, { useState, useEffect } from "react";
import LoadingFallback from "../UI/Spinner/LoadingFallback";

const customLazyLoad = (importFunc) => {
  const LazyComponent = React.lazy(importFunc);

  return (props) => {
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
      let isMounted = true;
      importFunc().then(() => {
        if (isMounted) setIsLoaded(true);
      });
      return () => {
        isMounted = false;
      };
    }, []);

    return isLoaded ? <LazyComponent {...props} /> : <LoadingFallback />;
  };
};

export default customLazyLoad;
