import * as actionTypes from "../actionTypes";
import Axios from "../../../services/NetworkService";
import store from "../../store/store";

export const hdcSensorPending = () => {
  return {
    type: actionTypes.HDC_SENSOR_PENDING
  };
};

export const hdcSensorComplete = () => {
  return {
    type: actionTypes.HDC_SENSOR_COMPLETE,
  };
};

export const hdcSensorSuccess = (data) => {
  return {
    type: actionTypes.HDC_SENSOR_SUCCESS,
    payload: data,
  };
};

export const hdcSensorFail = (error) => {
  return {
    type: actionTypes.HDC_SENSOR_FAIL,
    error: error,
  };
};

export const hdcSensorReset = () => {
  return {
    type: actionTypes.HDC_SENSOR_RESET,
  };
};

const fetchHdcSensorsData = async (timeFrame, sensors, dispatch) => {
  const hdcData = [];
  const hdcIds = [];

  for (let i = 0; i < sensors.length; i++) {
    if (store.getState().hdcSensor.isTerminated) {
      dispatch(hdcSensorReset());
      break;
    }

    const sensorName = sensors[i].label;
    const sensorId = sensors[i].value;

    hdcData.push({
      sensorName,
      sensorId,
      values: [],
    });
    hdcIds.push(sensorId);
  }

  if (hdcIds.length > 0) {
    const result = await Axios.post("/hdcdata", {
      timeFrame,
      deviceIds: hdcIds,
      resolution: 60,
    });
    hdcData.forEach((d) => (d.values = result.data.data[d.sensorId] || []));
    await dispatch(hdcSensorSuccess({ hdcData }));
  }

  dispatch(hdcSensorComplete());
}

export const hdcSensorAction = (timeframe, sensors) => {
  return async function (dispatch) {
    try {
      dispatch(hdcSensorPending());
      fetchHdcSensorsData(timeframe, sensors, dispatch);
    } catch (error) {
      return dispatch(hdcSensorFail(error));
    }
  };
};