/*
 * Title: smartriver reducer
 * Description: smartriver sensor reducer
*/

import { updateObject } from '../../../shared/Utility';
import * as actionTypes from '../../actions/actionTypes';

const initialState = {
    isLoading: false,
    data: null,
    error: null,
}

const smartriverInit = (state) => {
    return updateObject(state, {
        isLoading: true,
        data: null,
        error: null,
    });
}

const smartriverSuccess = (state, data) => {
    return updateObject(state, {
        isLoading: false,
        data: data,
        error: null
    });
}

const smartriverFail = (state, error) => {
    return updateObject(state, {
        isLoading: false,
        data: null,
        error: error
    });
}

const reducer = (state = initialState, action) => {
    const { type, data, error } = action;
    switch (type) {
        case actionTypes.SMARTRIVER_INIT: return smartriverInit(state);
        case actionTypes.SMARTRIVER_SUCCESS: return smartriverSuccess(state, data);
        case actionTypes.SMARTRIVER_FAIL: return smartriverFail(state, error);
        default: return state;
    }

};

export default reducer;