import { updateObject } from "../../../shared/Utility";
import * as actionTypes from "../../actions/actionTypes";

const initialState = {
  isLoading: false,
  data: null,
  error: null,
};

const riskDeletePending = (state) => {
  return updateObject(state, {
    isLoading: true,
    data: null,
    error: null,
  });
};

const riskDeleteSuccess = (state, data) => {
  return updateObject(state, {
    isLoading: false,
    data: data,
    error: null,
  });
};

const riskDeleteFail = (state, error) => {
  return updateObject(state, {
    isLoading: false,
    data: null,
    error: error,
  });
};

const reducer = (state = initialState, action) => {
  const { type, data, error } = action;
  switch (type) {
    case actionTypes.RISK_DELETE_PENDING:
      return riskDeletePending(state);
    case actionTypes.RISK_DELETE_SUCCESS:
      return riskDeleteSuccess(state, data);
    case actionTypes.RISK_DELETE_FAIL:
      return riskDeleteFail(state, error);
    default:
      return state;
  }
};

export default reducer;
