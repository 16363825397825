import * as actionTypes from "../actionTypes";
import Axios from "../../../services/NetworkService";

const podsInit = () => {
  return {
    type: actionTypes.POD_INIT,
  };
};

const podsSuccess = (data) => {
  return {
    type: actionTypes.POD_SUCCESS,
    data: data,
  };
};

const podsFail = (error) => {
  return {
    type: actionTypes.POD_FAIL,
    error: error,
  };
};

export const setShowPods = (flag) => {
  return {
    type: actionTypes.SHOW_PODS,
    data: flag,
  };
};

export const pods = (ags) => {
  let urlEndPoint = `/pods/getAll?ags=${ags}`;
  return (dispatch) => {
    dispatch(podsInit());
    Axios.get(urlEndPoint, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => {
        dispatch(podsSuccess(response.data));
      })
      .catch((error) => {
        dispatch(podsFail(error));
      });
  };
};
