import * as actionTypes from "../actionTypes";

export const addLocalOLFeature = (data) => {
    return {
        type: actionTypes.ADD_LOCAL_OL_FEATURE,
        data: data,
    };
};

export const removeLocalOLFeature = () => {
    return {
        type: actionTypes.REMOVE_LOCAL_OL_FEATURE,
    };
};