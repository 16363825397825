import { updateObject } from "../../../shared/Utility";
import * as actionTypes from "../../actions/actionTypes";

const initialState = {
  isLoading: false,
  data: null,
  error: null,
};

const changeEmailAccountPending = (state) => {
  return updateObject(state, {
    isLoading: true,
    data: null,
    error: null,
  });
};

const changeEmailAccountSuccess = (state, data) => {
  return updateObject(state, {
    isLoading: false,
    data: data,
    error: null,
  });
};

const changeEmailAccountFail = (state, error) => {
  return updateObject(state, {
    isLoading: false,
    data: null,
    error: error,
  });
};

const reducer = (state = initialState, action) => {
  const { type, data, error } = action;
  switch (type) {
    case actionTypes.DELETE_ACCOUNT_PENDING:
      return changeEmailAccountPending(state);
    case actionTypes.DELETE_ACCOUNT_SUCCESS:
      return changeEmailAccountSuccess(state, data);
    case actionTypes.DELETE_ACCOUNT_FAIL:
      return changeEmailAccountFail(state, error);
    default:
      return state;
  }
};

export default reducer;
