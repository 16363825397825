import { updateObject } from "../../../shared/Utility";
import * as actionType from "../../actions/actionTypes";

const initialState = {
  isLoading: false,
  data: [],
  removeMessage: null,
  error: null,
  showComments: false,
  newCommentStatus: false,
};

const webviewCommentsPending = (state) => {
  return updateObject(state, {
    isLoading: true,
    data: [],
    removeMessage: null,
    error: null,
  });
};

const webviewCommentsSuccess = (state, data) => {
  return updateObject(state, {
    isLoading: false,
    data: data,
    removeMessage: null,
    error: null,
  });
};
const webviewCommentsRemoveSuccess = (state, data, message) => {
  return updateObject(state, {
    isLoading: false,
    data: data,
    removeMessage: message,
    error: null,
  });
};

const webviewCommentsFail = (state, error) => {
  return updateObject(state, {
    isLoading: false,
    data: [],
    removeMessage: null,
    error: error,
  });
};

const toggleComments = (state) => {
  return updateObject(state, {
    showComments: !state.showComments,
  });
};

//update add new comments status
const updateNewCmntStauts = (state, data) => {
  return updateObject(state, {
    newCommentStatus: data,
  });
};

const updateWebviewCommentsSuccess = (state, data) => {
  return updateObject(state, {
    isLoading: false,
    removeMessage: data,
    error: null,
  });
};

const updateWebviewCommentsFail = (state, error) => {
  return updateObject(state, {
    isLoading: false,
    data: [],
    removeMessage: null,
    error: error,
  });
};

const reducer = (state = initialState, action) => {
  const { type, data, error, message } = action;
  switch (type) {
    case actionType.WEBVIEW_COMMENTS_PENDING:
      return webviewCommentsPending(state);
    case actionType.WEBVIEW_COMMENTS_SUCCESS:
      return webviewCommentsSuccess(state, data);
    case actionType.WEBVIEW_COMMENTS_REMOVE_SUCCESS:
      return webviewCommentsRemoveSuccess(state, data, message);
    case actionType.WEBVIEW_COMMENTS_FAIL:
      return webviewCommentsFail(state, error);
    case actionType.WEBVIEW_COMMENTS_STATUS:
      return toggleComments(state);
    case actionType.WEBVIEW_ADD_NEW_COMMENTS_STATUS:
      return updateNewCmntStauts(state, data);
    case actionType.UPDATE_WEBVIEW_COMMENTS_SUCCESS:
      return updateWebviewCommentsSuccess(state, data);
    case actionType.UPDATE_WEBVIEW_COMMENTS_FAIL:
      return updateWebviewCommentsFail(state, error);
    default:
      return state;
  }
};

export default reducer;
