import { updateObject } from "../../../shared/Utility";
import * as actionTypes from '../../actions/actionTypes';

const initialState = {
  isFinished: false,
  isLoading: false,
  isTerminated: false,
  smartRiverData: [],
  error: null,
};

const smartRiverSensorPending = (state) => {
  return updateObject(state, {
    isFinished: false,
    isLoading: true,
    isTerminated: false,
    smartRiverData: [],
    error: null,
  });
}

const smartRiverSensorComplete = (state) => {
  return updateObject(state, {
    isFinished: true,
    isLoading: false,
  });
}

const smartRiverSensorSuccess = (state, payload) => {
  const { smartRiverData } = payload;
  return updateObject(state, {
    isFinished: false,
    isLoading: false,
    smartRiverData: [...smartRiverData],
    error: null,
  });
}

const smartRiverSensorFail = (state, error) => {
  return updateObject(state, {
    isFinished: true,
    isLoading: false,
    smartRiverData: [],
    error: error,
  });
}

const smartRiverSensorReset = (state) => {
  return updateObject(state, {
    isFinished: false,
    isLoading: false,
    isTerminated: true,
    smartRiverData: [],
    error: null,
  });
}

const reducer = (state = initialState, action) => {
  const { type, payload, error } = action;
  switch (type) {
    case actionTypes.SMART_RIVER_PENDING: return smartRiverSensorPending(state);
    case actionTypes.SMART_RIVER_SUCCESS: return smartRiverSensorSuccess(state, payload);
    case actionTypes.SMART_RIVER_FAIL: return smartRiverSensorFail(state, error);
    case actionTypes.SMART_RIVER_COMPLETE: return smartRiverSensorComplete(state);
    case actionTypes.SMART_RIVER_RESET: return smartRiverSensorReset(state);
    default:
      return state;
  }
}

export default reducer;