import * as actionTypes from "../actionTypes";
import Axios from "../../../services/NetworkService";

export const deleteAccountPending = () => {
  return {
    type: actionTypes.DELETE_ACCOUNT_PENDING,
    payload: "loading...",
  };
};

export const deleteAccountSuccess = (data) => {
  return {
    type: actionTypes.DELETE_ACCOUNT_SUCCESS,
    payload: data,
  };
};

export const deleteAccountFail = (error) => {
  return {
    type: actionTypes.DELETE_ACCOUNT_FAIL,
    error: error,
  };
};

export const deleteAccouont = (id) => {
  return async (dispatch) => {
    try {
      dispatch(deleteAccountPending());
      const response = await Axios.delete(`/users/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
      return dispatch(deleteAccountSuccess(response.data));
    } catch (error) {
      return dispatch(deleteAccountFail(error));
    }
  };
};
