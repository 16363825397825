
// Dependencies
import * as actionTypes from '../../actions/actionTypes';
import { updateObject } from '../../../shared/Utility';

// register initial state
const initialState = {
    loading: false,
    error: null,
    data: null,
}

const regPending = (state) => {
    return updateObject(state, {
        loading: true,
        error: null,
        data: null,
    });
}

const regSuccess = (state, data) => {
    return updateObject(state, {
        loading: false,
        error: null,
        data: data,
    });
}

const regFail = (state, error) => {
    return updateObject(state, {
        loading: false,
        data: null,
        error: error,
    });
}

// register reducer
const reducer = (state = initialState, action) => {
    const { type, data, error } = action;
    switch (type) {
        case actionTypes.REG_PENDING:
            return regPending(state);
        case actionTypes.REG_SUCCESS:
            return regSuccess(state, data);
        case actionTypes.REG_FAIL:
            return regFail(state, error);
        default: return state;
    }
};

export default reducer;