import * as actionTypes from "../../actions/actionTypes";
import { updateObject } from "../../../shared/Utility";

const initialState = {
  isLoading: false,
  data: null,
  error: null,
};

const harmNotesPending = (state) => {
  return updateObject(state, {
    loading: true,
    data: null,
    error: null,
  });
};

const harmNotesSuccess = (state, data) => {
  return updateObject(state, {
    loading: false,
    data: data,
    error: null,
  });
};

const harmNotesFail = (state, error) => {
  return updateObject(state, {
    loading: false,
    data: null,
    error: error,
  });
};

const turnOffHarmNotes = (state) => {
  return updateObject(state, {
    showHarmNotes: false,
  });
};

const harmNotesReducer = (state = initialState, action) => {
  const { type, data, error } = action;
  switch (type) {
    case actionTypes.HARM_NOTES_PENDING:
      return harmNotesPending(state);
    case actionTypes.HARM_NOTES_SUCCESS:
      return harmNotesSuccess(state, data);
    case actionTypes.HARM_NOTES_FAIL:
      return harmNotesFail(state, error);
    case actionTypes.HARM_NOTES_OFF:
      return turnOffHarmNotes(state);
    default:
      return state;
  }
};

export default harmNotesReducer;
