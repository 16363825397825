import * as actionTypes from "../actionTypes";
import Axios from "../../../services/NetworkService";

const riskReportsProcessedPending = () => {
  return {
    type: actionTypes.RISK_REPORTS_PROCESSED_PENDING,
  };
};
const riskReportsProcessedSuccess = (data) => {
  return {
    type: actionTypes.RISK_REPORTS_PROCESSED_SUCCESS,
    data: data,
  };
};

const riskReportsProcessedFail = (error) => {
  return {
    type: actionTypes.RISK_REPORTS_PROCESSED_FAIL,
    error: error,
  };
};

export const riskReportsProcessedAction = (ags, page, limit) => {
  let urlEndPoint = `riskreports/getAll?ags=${ags}`;

  return async (dispatch) => {
    dispatch(riskReportsProcessedPending());
    Axios.post(
      urlEndPoint,
      {
        page: page,
        limit: limit,
        type: "processed",
      },
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => {
        dispatch(riskReportsProcessedSuccess(response.data));
      })
      .catch((error) => {
        dispatch(riskReportsProcessedFail(error));
      });
  };
};
