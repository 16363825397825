import * as actionTypes from "../actionTypes";
import Axios from "../../../services/NetworkService";

export const usersPending = () => {
  return {
    type: actionTypes.USERS_PENDING,
  };
};

export const usersSuccess = (data) => {
  return {
    type: actionTypes.USERS_SUCCESS,
    data: data,
  };
};

export const usersFail = (error) => {
  return {
    type: actionTypes.USERS_FAIL,
    error: error,
  };
};

export const usersReset = () => {
  return {
    type: actionTypes.USERS_RESET,
  };
};

export const usersAction = (ags, currentPage, userType, searchTerm) => {
  let urlEndPoint = `/users/getAll?ags=${ags}`;

  return async (dispatch) => {
    dispatch(usersPending());
    Axios.post(
      urlEndPoint,
      {
        page: currentPage,
        limit: 10,
        sort: -1,
        searchTerm: searchTerm,
        type: userType,
      },
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => {
        dispatch(usersSuccess(response.data));
      })
      .catch((error) => {
        dispatch(usersFail(error));
      });
  };
};
