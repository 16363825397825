import * as actionType from "../../actions/actionTypes";

const initialState = {
    loading: false,
    error: null,
    data: null,
};

export default function editCustomSubscriberReducer(state = initialState, action) {
    const { type, payload, error } = action;

    switch (type) {
        case actionType.EDIT_CUSTOM_SUBSCRIBER_PENDING:
            return {
                ...state,
                loading: true,
                error: null,
                data: null,
            };

        case actionType.EDIT_CUSTOM_SUBSCRIBER_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                data: payload,
            };

        case actionType.EDIT_CUSTOM_SUBSCRIBER_FAIL:
            return {
                ...state,
                loading: false,
                error: error,
                data: null,
            };

        default:
            return state;
    }
}