import { updateObject } from "../../../shared/Utility";
import * as actionType from "../../actions/actionTypes";

const initialState = {
  isLoading: false,
  isTerminated: false,
  smartRiverData: [],
  hdcData: [],
  gflData: [],
  error: null,
};

const mixChartPending = (state) => {
  return updateObject(state, {
    isLoading: true,
    isTerminated: false,
    smartRiverData: [],
    hdcData: [],
    gflData: [],
    error: null,
  });
};

const mixChartSuccess = (state, payload) => {
  const { smartRiverData, hdcData, gflData } = payload;
  return updateObject(state, {
    smartRiverData: [...smartRiverData],
    hdcData: [...hdcData],
    gflData: [...gflData],
    error: null,
  });
};

const mixChartFail = (state, error) => {
  return updateObject(state, {
    isLoading: false,
    smartRiverData: [],
    hdcData: [],
    gflData: [],
    error: error,
  });
};

const mixChartLoaded = (state) => {
  return updateObject(state, {
    isLoading: false,
    error: null,
  });
};

const mixChartReset = (state) => {
  return updateObject(state, {
    isLoading: false,
    isTerminated: true,
    smartRiverData: [],
    hdcData: [],
    gflData: [],
    error: null,
  });
};

const reducer = (state = initialState, action) => {
  const { type, payload, error } = action;
  switch (type) {
    case actionType.ANALYTICS_MIXCHART_PENDING:
      return mixChartPending(state);
    case actionType.ANALYTICS_MIXCHART_SUCCESS:
      return mixChartSuccess(state, payload);
    case actionType.ANALYTICS_MIXCHART_FAIL:
      return mixChartFail(state, error);
    case actionType.ANALYTICS_MIXCHART_LOADED:
      return mixChartLoaded(state);
    case actionType.ANALYTICS_MIXCHART_RESET:
      return mixChartReset(state);
    default:
      return state;
  }
};

export default reducer;
