import * as actionTypes from "../../actions/actionTypes";
const initialState = {
  showAboutModal: false,
};

const openAboutModal = (state, flag) => {
  return { ...state, showAboutModal: flag };
};

const reducer = (state = initialState, action) => {
  const { type, data } = action;
  switch (type) {
    case actionTypes.SHOW_ABOUT_MODAL:
      return openAboutModal(state, data);
    default:
      return state;
  }
};

export default reducer;
