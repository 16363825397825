import { updateObject } from "../../../shared/Utility";
import * as actionTypes from "../../actions/actionTypes";

const initialState = {
  isLoading: false,
  data: null,
  selectedAgs: "",
  selectedName: "",
  currentMapPosition: null,
  error: null,
};

const observerInit = (state) => {
  return updateObject(state, {
    isLoading: true,
    data: null,
    error: null,
  });
};

const observerSuccess = (state, data) => {
  return updateObject(state, {
    isLoading: false,
    data: data,
    error: null,
  });
};

const observerFail = (state, error) => {
  return updateObject(state, {
    isLoading: false,
    data: null,
    error: error,
  });
};

const selectObserverAgs = (state, data) => {
  return updateObject(state, {
    selectedAgs: data,
  });
};

const selectObserverName = (state, data) => {
  return updateObject(state, {
    selectedName: data,
  });
};

const currentMapPosition = (state, data) => {
  return updateObject(state, {
    currentMapPosition: data,
  });
};

const reducer = (state = initialState, action) => {
  const { type, data, error } = action;
  switch (type) {
    case actionTypes.OBSERVER_INIT:
      return observerInit(state);
    case actionTypes.OBSERVER_SUCCESS:
      return observerSuccess(state, data);
    case actionTypes.OBSERVER_FAIL:
      return observerFail(state, error);
    case actionTypes.OBSERVER_SELECT_AGS:
      return selectObserverAgs(state, data);
    case actionTypes.OBSERVER_SELECT_NAME:
      return selectObserverName(state, data);
    case actionTypes.OBSERVER_CURRENT_MAP_POSITION:
      return currentMapPosition(state, data);
    default:
      return state;
  }
};

export default reducer;
