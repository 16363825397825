import * as actionTypes from '../../actions/actionTypes';
import { updateObject } from '../../../shared/Utility';

const initialState = {
  isLoading: false,
  data: null,
  submitData: null,
  error: null,
}

const forgetPassInit = (state) => {
  return updateObject(state, { isLoading: true });
}

const forgetPassOTPSuccess = (state, data) => {
  return updateObject(state, { isLoading: false, data: data, error: null });
}

const forgetPassSubmitSuccess = (state, submitData) => {
  return updateObject(state, { isLoading: false, submitData: submitData, error: null });
}

const forgetPassFail = (state, error) => {
  return updateObject(state, { isLoading: false, data: null, error: error });
}

const verifyOTPSuccess = (state, submitData) => {
  return updateObject(state, { isLoading: false, data: submitData, error: null });
}

const verifyOTPFail = (state, error) => {
  return updateObject(state, { isLoading: false, data: null, error: error });
}

const reducer = (state = initialState, action) => {
  const { type, data, submitData, error } = action;
  switch (type) {
    case actionTypes.FORGETPASS_INIT: return forgetPassInit(state);
    case actionTypes.FORGETPASS_OTP_SUCCESS: return forgetPassOTPSuccess(state, data);
    case actionTypes.FORGETPASS_SUBMIT_SUCCESS: return forgetPassSubmitSuccess(state, submitData);
    case actionTypes.FORGETPASS_FAIL: return forgetPassFail(state, error);
    case actionTypes.VERIFY_OTP_SUCCESS: return verifyOTPSuccess(state, data);
    case actionTypes.VERIFY_OTP_FAIL: return verifyOTPFail(state, error);
    default: return state;
  }
};

export default reducer;