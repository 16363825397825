import * as actionTypes from "../actionTypes";
import Axios from "../../../services/NetworkService";

export const verifyRegTokenPending = () => {
  return {
    type: actionTypes.VERIFY_REGISTER_TOKEN_PENDING,
  };
};

export const verifyRegTokenSuccess = (data) => {
  return {
    type: actionTypes.VERIFY_REGISTER_TOKEN_SUCCESS,
    data: data,
  };
};

export const verifyRegTokenFail = (error) => {
  return {
    type: actionTypes.VERIFY_REGISTER_TOKEN_FAIL,
    error: error,
  };
};

export const verifyRegTokenAction = (token) => {
  let urlEndPoint = `/users/verifyRegisterToken/?token=${token}`;

  return async (dispatch) => {
    try {
      dispatch(verifyRegTokenPending());

      const response = await Axios.get(urlEndPoint);
      return dispatch(verifyRegTokenSuccess(response.data));
    } catch (error) {
      return dispatch(verifyRegTokenFail(error));
    }
  };
};
