import * as actionTypes from "../actionTypes";
import Axios from "../../../services/NetworkService";
// ADD_SUBSCRIBER
export const addSubscriberPending = () => {
  return {
    type: actionTypes.ADD_SUBSCRIBER_PENDING,
  };
};

export const addSubscriberSuccess = (data) => {
  return {
    type: actionTypes.ADD_SUBSCRIBER_SUCCESS,
    data: data,
  };
};

export const addSubscriberFail = (error) => {
  return {
    type: actionTypes.ADD_SUBSCRIBER_FAIL,
    error: error,
  };
};

export const addSubscriberAction = (ags, newSubscriberData) => {
  let urlEndPoint = `subscriber?ags=${ags}`;
  return async (dispatch) => {
    try {
      dispatch(addSubscriberPending());

      const response = await Axios.post(urlEndPoint, newSubscriberData);
      return dispatch(addSubscriberSuccess(response.data));
    } catch (error) {
      return dispatch(addSubscriberFail(error));
    }
  };
};
