import * as actionTypes from "../actionTypes";
import Axios from "../../../services/NetworkService";

export const catchmentInit = () => {
  return {
    type: actionTypes.CATCHMENT_INIT,
  };
};

export const catchmentSuccess = (data) => {
  return {
    type: actionTypes.CATCHMENT_SUCCESS,
    data: data,
  };
};

export const catchmentFail = (error) => {
  return {
    type: actionTypes.CATCHMENT_FAIL,
    error: error,
  };
};

export const catchment = (ags = "") => {
  let urlEndPoint = "/catchments";
  if (ags) {
    urlEndPoint = `/catchments?ags=${ags}`;
  }
  return (dispatch) => {
    dispatch(catchmentInit());
    Axios.get(urlEndPoint, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        // Authorization: token,
      },
    })
      .then((response) => {
        dispatch(catchmentSuccess(response.data.data));
      })
      .catch((error) => {
        dispatch(catchmentFail(error));
      });
  };
};
