import * as actionTypes from "../actionTypes";
import Axios from "../../../services/NetworkService";

export const riverscapeInit = () => {
  return {
    type: actionTypes.RIVERSCAPE_INIT,
  };
};

export const riverscapeSuccess = (data) => {
  return {
    type: actionTypes.RIVERSCAPE_SUCCESS,
    data: data,
  };
};

export const riverscapeFail = (error) => {
  return {
    type: actionTypes.RIVERSCAPE_FAIL,
    error: error,
  };
};

export const riverscape = () => {
  let urlEndPoint = "/riverscapes/getAll";

  return (dispatch) => {
    dispatch(riverscapeInit());
    Axios.get(urlEndPoint, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => {
        dispatch(riverscapeSuccess(response.data));
      })
      .catch((error) => {
        dispatch(riverscapeFail(error));
      });
  };
};
