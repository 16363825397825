import * as actionTypes from "../actionTypes";
import Axios from "../../../services/NetworkService";

const riskDeletePending = () => {
  return {
    type: actionTypes.RISK_DELETE_PENDING,
  };
};

const riskDeleteSuccess = (data) => {
  return {
    type: actionTypes.RISK_DELETE_SUCCESS,
    data: data,
  };
};

const riskDeleteFail = (error) => {
  return {
    type: actionTypes.RISK_DELETE_FAIL,
    error: error,
  };
};

export const riskDeleteAction = (ags, id) => {
  let urlEndPoint = `riskreports/${id}?ags=${ags}`;

  return async (dispatch) => {
    try {
      dispatch(riskDeletePending());

      const response = await Axios.delete(urlEndPoint, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });
      return dispatch(riskDeleteSuccess(response.data));
    } catch (error) {
      return dispatch(riskDeleteFail(error));
    }
  };
};
