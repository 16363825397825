/*
 * Title: gfl reducer
 * Description: gfl sensor reducer
*/

import { updateObject } from '../../../shared/Utility';
import * as actionTypes from '../../actions/actionTypes';

const initialState = {
    isLoading: false,
    data: null,
    error: null,
}

const gflInit = (state) => {
    return updateObject(state, {
        isLoading: true,
        data: null,
        error: null,
    });
}

const gflSuccess = (state, data) => {
    return updateObject(state, {
        isLoading: false,
        data: data,
        error: null
    });
}

const gflFail = (state, error) => {
    return updateObject(state, {
        isLoading: false,
        data: null,
        error: error
    });
}

const reducer = (state = initialState, action) => {
    const { type, data, error } = action;
    switch (type) {
        case actionTypes.GFL_INIT: return gflInit(state);
        case actionTypes.GFL_SUCCESS: return gflSuccess(state, data);
        case actionTypes.GFL_FAIL: return gflFail(state, error);
        default: return state;
    }

};

export default reducer;