import * as actionTypes from "../actionTypes";
import Axios from "../../../services/NetworkService";

export const phoneCheckPending = () => {
  return {
    type: actionTypes.PHONE_CHECK_PENDING,
  };
};

export const phoneCheckSuccess = (data) => {
  return {
    type: actionTypes.PHONE_CHECK_SUCCESS,
    data: data,
  };
};

export const phoneCheckFail = (error) => {
  return {
    type: actionTypes.PHONE_CHECK_FAIL,
    error: error,
  };
};

export const phoneCheckAction = () => {
  let urlEndPoint = `/subscriber/checkNumber`;

  return async (dispatch) => {
    dispatch(phoneCheckPending());
    Axios.get(urlEndPoint, {
      headers: {
        // Authorization: token,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        dispatch(phoneCheckSuccess(response.data));
      })
      .catch((error) => {
        dispatch(phoneCheckFail(error));
      });
  };
};
