import * as actionTypes from "../actionTypes";
import Axios from "../../../services/NetworkService";

export const observerInit = () => {
  return {
    type: actionTypes.OBSERVER_INIT,
  };
};

export const observerSuccess = (data) => {
  return {
    type: actionTypes.OBSERVER_SUCCESS,
    data: data,
  };
};

export const cobserverFail = (error) => {
  return {
    type: actionTypes.OBSERVER_FAIL,
    error: error,
  };
};

export const selectObserverAgs = (selectedAgs) => {
  return {
    type: actionTypes.OBSERVER_SELECT_AGS,
    data: selectedAgs,
  };
};

export const selectObserverName = (name) => {
  return {
    type: actionTypes.OBSERVER_SELECT_NAME,
    data: name,
  };
};

export const currentMapPosition = (position) => {
  return {
    type: actionTypes.OBSERVER_CURRENT_MAP_POSITION,
    data: position,
  };
};

export const observer = () => {
  let urlEndPoint = "/observers/getAllAreas";
  return (dispatch) => {
    dispatch(observerInit());
    Axios.get(urlEndPoint, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => {
        dispatch(observerSuccess(response.data));
      })
      .catch((error) => {
        dispatch(cobserverFail(error));
      });
  };
};
