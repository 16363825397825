import { updateObject } from "../../../shared/Utility";
import * as actionTypes from "../../actions/actionTypes";

const initialState = {
  isFinished: false,
  isLoading: false,
  isTerminated: false,
  smartSewerData: [],
  error: null,
};

const smartSewerSensorPending = (state) => {
  return updateObject(state, {
    isFinished: false,
    isLoading: true,
    isTerminated: false,
    smartSewerData: [],
    error: null,
  });
};

const smartSewerSensorComplete = (state) => {
  return updateObject(state, {
    isFinished: true,
    isLoading: false,
  });
};

const smartSewerSensorSuccess = (state, payload) => {
  const { smartSewerData } = payload;
  return updateObject(state, {
    isFinished: false,
    isLoading: false,
    smartSewerData: [...smartSewerData],
    error: null,
  });
};

const smartSewerSensorFail = (state, error) => {
  return updateObject(state, {
    isFinished: true,
    isLoading: false,
    smartSewerData: [],
    error: error,
  });
};

const smartSewerSensorReset = (state) => {
  return updateObject(state, {
    isFinished: false,
    isLoading: false,
    isTerminated: true,
    smartSewerData: [],
    error: null,
  });
};

const reducer = (state = initialState, action) => {
  const { type, payload, error } = action;
  switch (type) {
    case actionTypes.SMART_SEWER_PENDING:
      return smartSewerSensorPending(state);
    case actionTypes.SMART_SEWER_SUCCESS:
      return smartSewerSensorSuccess(state, payload);
    case actionTypes.SMART_SEWER_FAIL:
      return smartSewerSensorFail(state, error);
    case actionTypes.SMART_SEWER_COMPLETE:
      return smartSewerSensorComplete(state);
    case actionTypes.SMART_SEWER_RESET:
      return smartSewerSensorReset(state);
    default:
      return state;
  }
};

export default reducer;
