import { updateObject } from "../../../shared/Utility";
import * as actionType from "../../actions/actionTypes";

const initialState = {
    feature: null,
};


const addLocalOLFeature = (state, data) => {
    return updateObject(state, {
        feature: data,
    });
}

const RemoveLocalOLFeature = (state) => {
    return updateObject(state, {
        feature: null,
    });
}

const reducer = (state = initialState, action) => {
    const { type, data } = action;
    switch (type) {
        case actionType.ADD_LOCAL_OL_FEATURE: return addLocalOLFeature(state, data);
        case actionType.REMOVE_LOCAL_OL_FEATURE: return RemoveLocalOLFeature(state);
        default:
            return state;
    }
}

export default reducer;
