import * as actionTypes from "../actionTypes";
import Axios from "../../../services/NetworkService";

export const forgetPassInit = () => {
  return {
    type: actionTypes.FORGETPASS_INIT,
  };
};

export const forgetPassOTPSuccess = (data) => {
  return {
    type: actionTypes.FORGETPASS_OTP_SUCCESS,
    data: data,
  };
};

export const forgetPassSubmitSuccess = (data) => {
  return {
    type: actionTypes.FORGETPASS_SUBMIT_SUCCESS,
    data,
  };
};

export const forgetPassFail = (statusCode, data) => {
  return {
    type: actionTypes.FORGETPASS_FAIL,
    error: statusCode,
    data,
  };
};

export const verifyOTPSuccess = (data) => {
  return {
    type: actionTypes.VERIFY_OTP_SUCCESS,
    data,
  };
};

export const verifyOTPFail = (statusCode, data) => {
  return {
    type: actionTypes.VERIFY_OTP_FAIL,
    error: statusCode,
    data,
  };
};

export const forgetPassSentOTP = (email) => {
  let data = { email: email };

  return async (dispatch) => {
    try {
      dispatch(forgetPassInit());
      const response = await Axios.post("/password-recovery", data, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
      return dispatch(forgetPassOTPSuccess(response.data));
    } catch (error) {
      return dispatch(
        forgetPassFail(error.response.status, error.response.data)
      );
    }
  };
};

export const forgetPassSubmit = (otp, newPassword) => {
  let data = {
    token: otp,
    password: newPassword,
  };

  return async (dispatch) => {
    try {
      dispatch(forgetPassInit());
      const response = await Axios.patch("/password-recovery", data, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
      return dispatch(forgetPassSubmitSuccess(response.data));
    } catch (error) {
      return dispatch(forgetPassFail(error.response.status, error.response.data));
    }
  };
};

export const verifyOTP = (otp) => {
  return async (dispatch) => {
    try {
      const response = await Axios.post(
        "/password-recovery/verifyotp",
        { token: otp },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      return dispatch(verifyOTPSuccess(response.data));
    } catch (error) {
      return dispatch(
        verifyOTPFail(error.response.status, error.response.data)
      );
    }
  };
};
