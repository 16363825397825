import { updateObject } from "../../../shared/Utility";
import * as actionTypes from '../../actions/actionTypes';

const initialState = {
  isFinished: false,
  isLoading: false,
  isTerminated: false,
  gflData: [],
  error: null,
};

const gflSensorPending = (state) => {
  return updateObject(state, {
    isFinished: false,
    isLoading: true,
    isTerminated: false,
    gflData: [],
    error: null,
  });
}

const gflSensorComplete = (state) => {
  return updateObject(state, {
    isFinished: true,
    isLoading: false,
  });
}

const gflSensorSuccess = (state, payload) => {
  const { gflData } = payload;
  return updateObject(state, {
    isFinished: false,
    isLoading: false,
    gflData: [...gflData],
    error: null,
  });
}

const gflSensorFail = (state, error) => {
  return updateObject(state, {
    isFinished: true,
    isLoading: false,
    gflData: [],
    error: error,
  });
}

const gflSensorReset = (state) => {
  return updateObject(state, {
    isFinished: false,
    isLoading: false,
    isTerminated: true,
    smartRiverData: [],
    error: null,
  });
}

const reducer = (state = initialState, action) => {
  const { type, payload, error } = action;
  switch (type) {
    case actionTypes.GFL_SENSOR_PENDING: return gflSensorPending(state);
    case actionTypes.GFL_SENSOR_SUCCESS: return gflSensorSuccess(state, payload);
    case actionTypes.GFL_SENSOR_FAIL: return gflSensorFail(state, error);
    case actionTypes.GFL_SENSOR_COMPLETE: return gflSensorComplete(state);
    case actionTypes.GFL_SENSOR_RESET: return gflSensorReset(state);
    default:
      return state;
  }
}

export default reducer;