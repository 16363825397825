import * as actionType from "../../actions/actionTypes";

const initialState = {
  loading: false,
  error: null,
  data: null,
};

export default function addSpecialUserReducer(state = initialState, action) {
  const { type, payload, error } = action;

  switch (type) {
    case actionType.ADD_SPECIAL_USER_PENDING:
      return {
        ...state,
        loading: true,
        error: null,
        data: null,
      };

    case actionType.ADD_SPECIAL_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        data: payload,
      };

    case actionType.ADD_SPECIAL_USER_FAIL:
      return {
        ...state,
        loading: false,
        error: error,
        data: null,
      };

    default:
      return state;
  }
}
