import * as actionTypes from "../actionTypes";
import Axios from "../../../services/NetworkService";

export const completeRegisterPending = () => {
  return {
    type: actionTypes.COMPLETE_REGISTER_PENDING,
  };
};

export const completeRegisterSuccess = (data) => {
  return {
    type: actionTypes.COMPLETE_REGISTER_SUCCESS,
    data: data,
  };
};

export const completeRegisterFail = (error) => {
  return {
    type: actionTypes.COMPLETE_REGISTER_FAIL,
    error: error,
  };
};

export const completeRegisterAction = (regData) => {
  let urlEndPoint = "/users/completeRegistration";

  return async (dispatch) => {
    try {
      dispatch(completeRegisterPending());

      const response = await Axios.post(urlEndPoint, regData);
      return dispatch(completeRegisterSuccess(response.data));
    } catch (error) {
      return dispatch(completeRegisterFail(error));
    }
  };
};
