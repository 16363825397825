import * as actionTypes from "../actionTypes";
import Axios from "../../../services/NetworkService";

export const sensorNotiPending = () => {
  return {
    type: actionTypes.SENSOR_NOTIFICATIONS_PENDING,
  };
};

export const sensorNotiSuccess = (data) => {
  return {
    type: actionTypes.SENSOR_NOTIFICATIONS_SUCCESS,
    data: data,
  };
};

export const sensorNotiFail = (error) => {
  return {
    type: actionTypes.SENSOR_NOTIFICATIONS_FAIL,
    error: error,
  };
};

export const sensorNotification = (agsString) => {
  let urlEndPoint = `/notifications/getSensorNotifications?ags=${agsString}`;

  return async (dispatch) => {
    dispatch(sensorNotiPending());
    Axios.get(urlEndPoint, {
      headers: {
        // Authorization: token,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        dispatch(sensorNotiSuccess(response.data));
      })
      .catch((error) => {
        dispatch(sensorNotiFail(error));
      });
  };
};
