import * as actionTypes from "../actionTypes";
import Axios from "../../../services/NetworkService";
import { ownRescueSubscriberActionSuccess } from "./ownRecueSubscriberAction";

export const userActionPending = () => {
  return {
    type: actionTypes.USER_PENDING,
  };
};

export const userActionSuccess = (data) => {
  return {
    type: actionTypes.USER_SUCCESS,
    data: data,
  };
};

export const userActionFail = (error) => {
  return {
    type: actionTypes.USER_FAIL,
    error: error,
  };
};

export const userAction = () => {
  let urlEndPoint = "/users/me";

  return async (dispatch) => {
    dispatch(userActionPending());
    Axios.get(urlEndPoint, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        dispatch(userActionSuccess(response.data));
        dispatch(ownRescueSubscriberActionSuccess(response.data.subscriber));
      })
      .catch((error) => {
        dispatch(userActionFail(error));
      });
  };
};
