import { updateObject } from '../../../shared/Utility';
import * as actionTypes from '../../actions/actionTypes';

const initialState = {
    isLoading: false,
    data: null,
    error: null,
}

const riskSubmitPending = (state) => {
    return updateObject(state, {
        isLoading: true,
        data: null,
        error: null,
    });
}

const riskSubmitSuccess = (state, data) => {
    return updateObject(state, {
        isLoading: false,
        data: data,
        error: null
    });
}

const riskSubmitFail = (state, error) => {
    return updateObject(state, {
        isLoading: false,
        data: null,
        error: error
    });
}

const reducer = (state = initialState, action) => {
    const { type, data, error } = action;
    switch (type) {
        case actionTypes.RISK_SUBMIT_PENDING: return riskSubmitPending(state);
        case actionTypes.RISK_SUBMIT_SUCCESS: return riskSubmitSuccess(state, data);
        case actionTypes.RISK_SUBMIT_FAIL: return riskSubmitFail(state, error);
        default: return state;
    }
};

export default reducer;