import { useState, useEffect } from "react";
import useWindowDimensions from "./useWindowDimensions";

const useMobileView = () => {
  const [isMobileView, setIsMobileView] = useState(false);
  const { width } = useWindowDimensions();

  useEffect(() => {
    if (width <= 640) {
      setIsMobileView(true);
    } else {
      setIsMobileView(false);
    }
  }, [width]);

  return isMobileView;
};

export default useMobileView;
