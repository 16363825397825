import { updateObject } from '../../../shared/Utility';
import * as actionTypes from '../../actions/actionTypes';

const initialState = {
    isLoading: false,
    data: null,
    error: null,
}

const allCustomSubscribersPending = (state) => {
    return updateObject(state, {
        isLoading: true,
        data: null,
        error: null,
    });
}

const allCustomSubscribersSuccess = (state, data) => {
    return updateObject(state, {
        isLoading: false,
        data: data,
        error: null
    });
}

const allCustomSubscribersFail = (state, error) => {
    return updateObject(state, {
        isLoading: false,
        data: null,
        error: error
    });
}

const reducer = (state = initialState, action) => {
    const { type, data, error } = action;
    switch (type) {
        case actionTypes.ALL_CUSTOM_SUBSCRIBERS_PENDING: return allCustomSubscribersPending(state);
        case actionTypes.ALL_CUSTOM_SUBSCRIBERS_SUCCESS: return allCustomSubscribersSuccess(state, data);
        case actionTypes.ALL_CUSTOM_SUBSCRIBERS_FAIL: return allCustomSubscribersFail(state, error);
        default: return state;
    }
};

export default reducer;

// import * as actionType from "../../actions/actionTypes";

// const initialState = {
//     loading: false,
//     error: null,
//     data: null,
// };

// export default function allCustomSubscribersReducer(state = initialState, action) {
//     const { type, payload, error } = action;

//     switch (type) {
//         case actionType.ALL_CUSTOM_SUBSCRIBERS_PENDING:
//             return {
//                 ...state,
//                 loading: true,
//                 error: null,
//                 data: null,
//             };

//         case actionType.ALL_CUSTOM_SUBSCRIBERS_SUCCESS:
//             return {
//                 ...state,
//                 loading: false,
//                 error: null,
//                 data: payload,
//             };

//         case actionType.ALL_CUSTOM_SUBSCRIBERS_FAIL:
//             return {
//                 ...state,
//                 loading: false,
//                 error: error,
//                 data: null,
//             };

//         default:
//             return state;
//     }
// }
