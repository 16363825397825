// components/LoadingFallback.js
import React, { useEffect } from "react";
import NProgress from "nprogress";
import "nprogress/nprogress.css";

const LoadingFallback = () => {
  useEffect(() => {
    NProgress.start();
    return () => {
      NProgress.done();
    };
  }, []);

  return null; // No need to return anything as NProgress handles the UI
};

export default LoadingFallback;
