// export const message_en = {
//   loginError: "Incorrect email or password",
// };

// export const message_de = {
//   loginError: "Falsche E-Mail-Adresse oder Passwort",
// };

export const customErrorMessages = {
  "Email Already Exists": "E-Mail-Adresse existiert bereits",
  "Incorrect email or password": "Falsche E-Mail-Adresse oder Passwort",
  "Please check your entry": "Bitte überprüfen Sie Ihre Eingabe",
  "Incorrect municipality or plot number - Please verify the entry":
    "Falsche Gemarkung oder Flurstücknummer - Bitte Eingabe prüfen",
  "Official municipal key (AGS) not found":
    "Amtlicher Gemeindeschlüssel (AGS) nicht gefunden",
  "Risk profile inquiry is not permissible for your municipality":
    "Risikoprofil-Anfrage ist für Ihre Kommune nicht zulässig",
  "Authorizing risk profile is not possible":
    "Risikoprofil autorisieren nicht möglich",
  "Request has already been authorized": "Anfrage wurde bereits authorisiert",
  "Risk profile cannot be declined": "Risikoprofil kann nicht abgelehnt werden",
  "Request has already been declined": "Anfrage wurde bereits abgelehnt",
  "Your account is not verified yet by your municipality. Please try again later":
    "Ihr Account wurde noch nicht von Ihrer Kommune verifiziert. Bitte versuchen Sie es später erneut",
  "Inquiry authorized successfully": "Risikoprofil-Anfrage erfolgreich authorisiert",
  "Inquiry declined successfully": "Risikoprofil-Anfrage erfolgreich abgelehnt",
  "Information updated successfully": "Informationen erfolgreich aktualisiert",
  "Link expired":"Link abgelaufen",
  "Please fill in both phone number fields":"Bitte füllen Sie beide Telefonnummernfelder aus.",
  "There are inconsistencies in the phone number you entered. Please enter a correct phone number":"Ihre eingetragene Telefonnummer weist unstimmigkeiten auf. Bitte geben Sie eine korrekte Telefonnummer ein.",
  "Inquiry deleted successfully":"Anfrage erfolgreich gelöscht",
};
