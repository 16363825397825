import * as actionTypes from "../actionTypes";
import Axios from "../../../services/NetworkService";

const allCustomSubscribersPending = () => {
  return {
    type: actionTypes.ALL_CUSTOM_SUBSCRIBERS_PENDING,
  };
};
const allCustomSubscribersSuccess = (data) => {
  return {
    type: actionTypes.ALL_CUSTOM_SUBSCRIBERS_SUCCESS,
    data: data,
  };
};

const allCustomSubscribersFail = (error) => {
  return {
    type: actionTypes.ALL_CUSTOM_SUBSCRIBERS_FAIL,
    error: error,
  };
};

export const allCustomSubscribersAction = (ags, info) => {
  let urlEndPoint = `subscriber/getAllCustomSub?ags=${ags}`;

  return async (dispatch) => {
    dispatch(allCustomSubscribersPending());
    Axios.post(urlEndPoint, info, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        dispatch(allCustomSubscribersSuccess(response.data));
      })
      .catch((error) => {
        dispatch(allCustomSubscribersFail(error));
      });
  };
};
