import { updateObject } from "../../../shared/Utility";
import * as actionTypes from '../../actions/actionTypes';

const initialState = {
  isFinished: false,
  isLoading: false,
  isTerminated: false,
  hdcData: [],
  error: null,
};

const hdcSensorPending = (state) => {
  return updateObject(state, {
    isFinished: false,
    isLoading: true,
    isTerminated: false,
    hdcData: [],
    error: null,
  });
}

const hdcSensorComplete = (state) => {
  return updateObject(state, {
    isFinished: true,
    isLoading: false,
  });
}

const hdcSensorSuccess = (state, payload) => {
  const { hdcData } = payload;
  return updateObject(state, {
    isFinished: false,
    isLoading: false,
    hdcData: [...hdcData],
    error: null,
  });
}

const hdcSensorFail = (state, error) => {
  return updateObject(state, {
    isFinished: true,
    isLoading: false,
    hdcData: [],
    error: error,
  });
}

const hdcSensorReset = (state) => {
  return updateObject(state, {
    isFinished: false,
    isLoading: false,
    isTerminated: true,
    smartRiverData: [],
    error: null,
  });
}

const reducer = (state = initialState, action) => {
  const { type, payload, error } = action;
  switch (type) {
    case actionTypes.HDC_SENSOR_PENDING: return hdcSensorPending(state);
    case actionTypes.HDC_SENSOR_SUCCESS: return hdcSensorSuccess(state, payload);
    case actionTypes.HDC_SENSOR_FAIL: return hdcSensorFail(state, error);
    case actionTypes.HDC_SENSOR_COMPLETE: return hdcSensorComplete(state);
    case actionTypes.HDC_SENSOR_RESET: return hdcSensorReset(state);
    default:
      return state;
  }
}

export default reducer;