import { updateObject } from "../../../shared/Utility";
import * as actionTypes from "../../actions/actionTypes";

const initialState = {
  isLoading: false,
  data: null,
  error: null,
};

const sensorNotiPending = (state) => {
  return updateObject(state, {
    isLoading: true,
    data: null,
    error: null,
  });
};

const sensorNotiSuccess = (state, data) => {
  return updateObject(state, {
    isLoading: false,
    data: data,
    error: null,
  });
};

const sensorNotiFail = (state, error) => {
  return updateObject(state, {
    isLoading: false,
    data: null,
    error: error,
  });
};

const reducer = (state = initialState, action) => {
  const { type, data, error } = action;
  switch (type) {
    case actionTypes.SENSOR_NOTIFICATIONS_PENDING:
      return sensorNotiPending(state);
    case actionTypes.SENSOR_NOTIFICATIONS_SUCCESS:
      return sensorNotiSuccess(state, data);
    case actionTypes.SENSOR_NOTIFICATIONS_FAIL:
      return sensorNotiFail(state, error);
    default:
      return state;
  }
};

export default reducer;
