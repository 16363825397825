import * as actionTypes from "../actionTypes";
import Axios from "../../../services/NetworkService";

export const changeEmailAccountPending = () => {
  return {
    type: actionTypes.CHANGE_EMAIL_PENDING,
  };
};

export const changeEmailAccountSuccess = (data) => {
  return {
    type: actionTypes.CHANGE_EMAIL_SUCCESS,
    payload: data,
  };
};

export const changeEmailAccountFail = (error) => {
  return {
    type: actionTypes.CHANGE_EMAIL_FAIL,
    error: error,
  };
};

export const changeEmailAccount = (token) => {
  return async (dispatch) => {
    try {
      dispatch(changeEmailAccountPending());
      const response = await Axios.patch(`/emailchange/${token}`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
      return dispatch(changeEmailAccountSuccess(response.data));
    } catch (error) {
      return dispatch(changeEmailAccountFail(error));
    }
  };
};
