import { updateObject } from "../../../shared/Utility";
import * as actionTypes from "../../actions/actionTypes";

const initialState = {
  showPods: false,
  pods: {
    isLoading: false,
    data: [],
    error: null,
  },
};

const podsInit = (state) => {
  return updateObject(state, {
    pods: {
      isLoading: true,
      data: [],
      error: null,
    },
  });
};

const podsSuccess = (state, data) => {
  return updateObject(state, {
    pods: {
      isLoading: false,
      data: data,
      error: null,
    },
  });
};

const podsFail = (state, error) => {
  return updateObject(state, {
    pods: {
      isLoading: false,
      data: [],
      error: error,
    },
  });
};

const setShowPods = (state, flag) => {
  return updateObject(state, {
    showPods: flag,
  });
};

const reducer = (state = initialState, action) => {
  const { type, data, error } = action;
  switch (type) {
    case actionTypes.SHOW_PODS:
      return setShowPods(state, data);

    case actionTypes.POD_INIT:
      return podsInit(state, data);
    case actionTypes.POD_SUCCESS:
      return podsSuccess(state, data);
    case actionTypes.POD_FAIL:
      return podsFail(state, error);
    default:
      return state;
  }
};

export default reducer;
