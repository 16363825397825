import * as actionType from "../actionTypes";
import Axios, { resInterceptor } from "../../../services/NetworkService";

export const loginInit = () => {
  return {
    type: actionType.LOGIN_PENDING,
    payload: "loading...",
  };
};

export const loginSuccess = (data) => {
  return {
    type: actionType.LOGIN_SUCCESS,
    payload: data,
  };
};
export const loginReset = () => {
  // localStorage.clear();
  return {
    type: actionType.LOGIN_RESET,
  };
};

export const loginFail = (error) => {
  return {
    type: actionType.LOGIN_FAIL,
    error: {
      message: error.response.data.message,
      statusCode: error.response ? error.response.status : error.message,
    },
  };
};

export const login = (userCredential) => {
  return async (dispatch) => {
    try {
      dispatch(loginInit());

      // eject response interceptor
      Axios.interceptors.response.eject(resInterceptor);

      const response = await Axios.post("/authentication", userCredential);

      let authData = {};
      if (response.data.accessToken) {
        let responseAuthData = response.data;
        const roles = { ...responseAuthData.user?.roles };
        const selectedAgs = Object.keys(roles)[0];
        const selectedRole = roles[selectedAgs]?.includes("managers")
          ? roles[selectedAgs][1]
          : roles[selectedAgs]?.includes("rescue")
          ? roles[selectedAgs][1]
          : roles[selectedAgs][0];

        authData = {
          ...responseAuthData,
          user: {
            ...responseAuthData.user,
            ags: selectedAgs,
            role: selectedRole,
          },
        };
        localStorage.setItem("auth", JSON.stringify(authData));
      }
      return dispatch(loginSuccess({ ...authData }));
    } catch (error) {
      return dispatch(loginFail(error));
    }
  };
};
