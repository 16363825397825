import React from "react";

const Spinner = () => {
  return (
    <div
      className="
    spinner-border
    animate-spin
    inline-block
    w-8
    h-8
    border-4
    rounded-full
    text-spekterRed
  "
      role="status"
    ></div>
  );
};

export default Spinner;
