import * as actionTypes from "../../actions/actionTypes";
import { updateObject } from "../../../shared/Utility";

const initialState = {
  isLoading: false,
  data: null,
  error: null,
};

const harmReportsPending = (state) => {
  return updateObject(state, {
    loading: true,
    data: null,
    error: null,
  });
};

const harmReportsSuccess = (state, data) => {
  return updateObject(state, {
    loading: false,
    data: data,
    error: null,
  });
};

const harmReportsFail = (state, error) => {
  return updateObject(state, {
    loading: false,
    data: null,
    error: error,
  });
};

const turnOffHarmReports = (state) => {
  return updateObject(state, {
    showHarmReports: false,
  });
};

const harmReportsReducer = (state = initialState, action) => {
  const { type, data, error } = action;
  switch (type) {
    case actionTypes.HARM_REPORTS_PENDING:
      return harmReportsPending(state);
    case actionTypes.HARM_REPORTS_SUCCESS:
      return harmReportsSuccess(state, data);
    case actionTypes.HARM_REPORTS_FAIL:
      return harmReportsFail(state, error);
    case actionTypes.HARM_REPORTS_OFF:
      return turnOffHarmReports(state);
    default:
      return state;
  }
};

export default harmReportsReducer;
