import * as actionTypes from "../actionTypes";
import Axios from "../../../services/NetworkService";

export const subscriberActionPending = () => {
  return {
    type: actionTypes.SUBSCRIBER_PENDING,
  };
};

export const subscriberActionSuccess = (data) => {
  return {
    type: actionTypes.SUBSCRIBER_SUCCESS,
    data: data,
  };
};

export const subscriberActionFail = (error) => {
  return {
    type: actionTypes.SUBSCRIBER_FAIL,
    error: error,
  };
};

export const updateSubsciberAction = (ags, id, arg) => {
  let urlEndPoint = `/subscriber/${id}?ags=${ags}`;

  return async (dispatch) => {
    dispatch(subscriberActionPending());
    try {
      const response = await Axios.patch(urlEndPoint, arg, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });
      return dispatch(subscriberActionSuccess(response.data));
    } catch (error) {
      return dispatch(subscriberActionFail(error));
    }
  };
};

export const subsciberAction = (id) => {
  let urlEndPoint = "/subscriber/" + id;

  return async (dispatch) => {
    dispatch(subscriberActionPending());
    try {
      const response = await Axios.get(urlEndPoint, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });
      return dispatch(subscriberActionSuccess(response.data));
    } catch (error) {
      return dispatch(subscriberActionFail(error));
    }
  };
};
