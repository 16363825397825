import * as actionType from "../../actions/actionTypes";
const initialState = {
  data: null,
  isLoading: false,
  error: null,
};

export default function loginReducer(state = initialState, action) {
  const { type, payload, error } = action;
  switch (type) {
    case actionType.LOGIN_PENDING:
      return {
        ...state,
        user: null,
        isLoading: true,
        error: null,
      };

    case actionType.LOGIN_SUCCESS:
      return {
        ...state,
        data: payload,
        isLoading: false,
        error: null,
      };
    case actionType.LOGIN_RESET:
      return {
        ...state,
        data: {},
        isLoading: false,
        error: null,
      };

    case actionType.LOGIN_FAIL:
      return {
        ...state,
        user: null,
        isLoading: false,
        error: error,
      };

    default:
      return state;
  }
}
