import * as actionType from "../actionTypes";
import Axios from "../../../services/NetworkService";
import { sensorTypes } from "../../../constants/sensorTypes";
import { getStartEndDateByTimeFrame } from "../../../shared/Utility";
import moment from "moment";

//  sensorType, deviceId, timeframe
export function chartAction(
  sensorType,
  deviceId,
  timeFrame,
  sensorName,
  optionalData
) {
  let deviceIds = [deviceId];

  return async function (dispatch) {
    try {
      dispatch({ type: actionType.CHART_PENDING, payload: "loading..." });

      // Berechne Start- und Endzeit basierend auf dem aktuellen Zeitpunkt
      const end = new Date();
      const start = new Date(end.getTime() - timeFrame * 60000); // timeFrame ist in Minuten

      console.log(
        "Requesting data from",
        start.toISOString(),
        "to",
        end.toISOString()
      );

      let response;
      if (sensorType === sensorTypes.SMARTRIVER) {
        response = await Axios.post("/smartriverdata", {
          // timeFrame,
          start: start.toISOString(),
          end: end.toISOString(),
          deviceIds,
          filter: true,
          // resolution: 5
          // resolution: timeFrame === 1440 ? 5 : 60,
        });
      } else if (sensorType === sensorTypes.SMARTSEWER) {
        response = await Axios.post("/smartsewerdata", {
          timeFrame,
          deviceIds,
          resolution: 300,
        });
      } else if (sensorType === sensorTypes.GFL) {
        response = await Axios.post("/gfldata", {
          start: start.toISOString(),
          end: end.toISOString(),
          // timeFrame,
          deviceIds,
          resolution: 60,
        });
      } else if (sensorType === sensorTypes.HDC) {
        const { start: hdcStart, end: hdcEnd } =
          getStartEndDateByTimeFrame(timeFrame);
        response = await Axios.post("/hdcdata", {
          start: moment(hdcStart).subtract(1, "hour").toISOString(),
          end: hdcEnd.toISOString(),
          deviceIds,
          resolution: 60,
        });
      } else if (sensorType === sensorTypes.EXTERNALFS) {
        response = await Axios.post("/externalfsdata", {
          start: start.toISOString(),
          end: end.toISOString(),
          deviceIds,
        });
      }

      console.log("Received data:", response.data.data[deviceId]);

      return dispatch({
        type: actionType.CHART_SUCCESS,
        payload: response.data.data[deviceId] || [],
        sensorId: deviceId,
        sensorName,
        sensorType,
        optionalData,
      });
    } catch (error) {
      console.error("Error fetching chart data:", error);
      return dispatch({
        type: actionType.CHART_FAIL,
        error: error,
      });
    }
  };
}
