import * as actionTypes from "../actionTypes";
import Axios from "../../../services/NetworkService";

const riskHandlePending = () => {
  return {
    type: actionTypes.RISK_HANDLE_PENDING,
  };
};
const riskHandleSuccess = (data) => {
  return {
    type: actionTypes.RISK_HANDLE_SUCCESS,
    data: data,
  };
};

const riskHandleFail = (error) => {
  return {
    type: actionTypes.RISK_HANDLE_FAIL,
    error: error,
  };
};

export const riskHandleAction = (ags, option, id) => {
  let urlEndPoint = `riskreports/${option}/${id}?ags=${ags}`;

  return async (dispatch) => {
    try {
      dispatch(riskHandlePending());

      const response = await Axios.patch(urlEndPoint, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });
      return dispatch(riskHandleSuccess(response.data));
    } catch (error) {
      return dispatch(riskHandleFail(error));
    }
  };
};
