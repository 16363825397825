import * as actionTypes from "../actionTypes";
import Axios from "../../../services/NetworkService";

export const ownRescueSubscriberActionPending = () => {
  return {
    type: actionTypes.OWN_RESCUE_SUBSCRIBER_PENDING,
  };
};

export const ownRescueSubscriberActionSuccess = (data) => {
  return {
    type: actionTypes.OWN_RESCUE_SUBSCRIBER_SUCCESS,
    data: data,
  };
};

export const ownRescueSubscriberActionFail = (error) => {
  return {
    type: actionTypes.OWN_RESCUE_SUBSCRIBER_FAIL,
    error: error,
  };
};

export const ownRescueUpdateSubsciberAction = (id, arg) => {
  let urlEndPoint = "/subscriber/" + id;

  return async (dispatch) => {
    try {
      dispatch(ownRescueSubscriberActionPending());
      const response = await Axios.patch(urlEndPoint, arg, {
        headers: {
          // Authorization: token,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });
      return dispatch(ownRescueSubscriberActionSuccess(response.data));
    } catch (error) {
      return dispatch(ownRescueSubscriberActionFail(error));
    }
  };
};

export const ownRescueSubscriberAction = () => {
  let urlEndPoint = "/users/me";

  return async (dispatch) => {
    dispatch(ownRescueSubscriberActionPending());
    Axios.get(urlEndPoint, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        dispatch(ownRescueSubscriberActionSuccess(response.data));
      })
      .catch((error) => {
        dispatch(ownRescueSubscriberActionFail(error));
      });
  };
};
