import { updateObject } from '../../../shared/Utility';
import * as actionTypes from '../../actions/actionTypes';

const initialState = {
  isLoading: false,
  data: null,
  error: null,
}

const updateUserPending = (state) => {
  return updateObject(state, {
    isLoading: true,
    data: null,
    error: null,
  });
}

const updateUserSuccess = (state, data) => {
  return updateObject(state, {
    isLoading: false,
    data: data,
    error: null
  });
}

const updateUserFail = (state, error) => {
  return updateObject(state, {
    isLoading: false,
    data: null,
    error: error
  });
}

const reducer = (state = initialState, action) => {
  const { type, data, error } = action;
  switch (type) {
    case actionTypes.UPDATE_USER_PENDING: return updateUserPending(state);
    case actionTypes.UPDATE_USER_SUCCESS: return updateUserSuccess(state, data);
    case actionTypes.UPDATE_USER_FAIL: return updateUserFail(state, error);
    default: return state;
  }
};

export default reducer;