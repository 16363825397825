import * as actionTypes from "../../actions/actionTypes";
const initialState = {
  show: false,
};

const showDangerPagePopup = (state, data) => {
  return { ...state, show: data };
};

const reducer = (state = initialState, action) => {
  const { type, data } = action;
  switch (type) {
    case actionTypes.SHOW_DANGER_PAGE_POPUP:
      return showDangerPagePopup(state, data);
    default:
      return state;
  }
};

export default reducer;
